import React, { useEffect } from "react";
import Exchanges from "./components/Exchanges";
import Main from "./components/Main";
import { Outlet, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWindowSize from "@rooks/use-window-size";
import {
  FaAmazonPay,
  FaApplePay,
  FaBeer,
  FaCcMastercard,
  FaCcPaypal,
  FaCcVisa,
  FaGooglePay,
} from "react-icons/fa";
import { IconName } from "react-icons/fa6";
import { getUsdToInr } from "./apis/currency";
import logo from "./assets/logo.jpg";
export default function Root() {
  const [rate, setRate] = React.useState(81.99);
  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize();
  const [render, setRender] = React.useState(false);
  const location = useLocation();

  const getExchangeRate = async () => {
    const rate = await getUsdToInr();
    console.log("My rate", rate);
    setRate(rate);
  };

  useEffect(() => {
    // getExchangeRate()
    console.log(location);
  }, [location]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          color: "white",
          backgroundColor: "#FBF8F4",
          height: "100%",
          alignItems: "flex-start",
        }}
      >
        {innerWidth > 700 ? (
          <div id="sidebar">
            <div style={{
              display: "flex",
              alignSelf: "flex-start",
              flexDirection: "column",
              padding: 15
            }}>
              <a
                href="https://rupex.in" rel="noreferrer" style={{
                  alignSelf: 'flex-start',
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: "10vh",
                    width: "5vw",

                  }}
                />
              </a>
            </div>
            <div
              style={{
                display: "flex",
                alignSelf: "flex-start",
                flexDirection: "column",
              }}
            >

              <h4
                style={{
                  marginLeft: "-10%",
                  fontWeight: "bold",
                }}
              >
                USDT to INR service
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                  alignItems: "flex-start",
                  width: "90%",
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  marginLeft: "-10%",
                  padding: 20,
                }}
              >
                <h2
                  style={{
                    fontSize: 18,
                    alignSelf: "flex-start",
                    color: "#8BFBAF",
                    fontWeight: "bold",
                  }}
                >
                  Step 1 : Live Rates
                </h2>
                <nav>
                  <Link to={"/swap"}>
                    Enter your USDT amount to get the <br />
                    rate and know exactly what you'll
                    <br />
                    receive in Rupees
                  </Link>
                </nav>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                  alignItems: "flex-start",
                  width: "90%",
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  marginLeft: "-10%",
                  padding: 18,
                }}
              >
                <h2
                  style={{ fontSize: 18, color: "#8BFBAF", fontWeight: "bold" }}
                >
                  <span style={{ fontSize: 18, color: "#8BFBAF", fontWeight: "bold" }}>  Step 2 </span>: Account Details
                </h2>
                <nav>
                  <Link to={"/account"}>
                    Select either waller or bank and enter the <br />
                    details where you want us to send your funds
                  </Link>
                </nav>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  marginLeft: "-10%",
                  padding: 20,
                }}
              >
                <h2
                  style={{ fontSize: 18, color: "#8BFBAF", fontWeight: "bold" }}
                >
                  Step 3 : Transfer USDT
                </h2>
                <nav>
                  <Link to={"/qrcode"}>
                    Initiate your USDT transafer by selecting the
                    <br />
                    correct network and then scanning our QR <br />
                    code or by copying our wallet address into<br /> your USDT wallet
                  </Link>
                </nav>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  marginLeft: "-6%",
                  padding: 10,
                }}
              >
                <h2
                  style={{ fontSize: 17, color: "#8BFBAF", fontWeight: "bold" }}
                >
                  Final Step : Wallet Address
                </h2>

                <nav>
                  <Link to={"/qrcode"}>
                    Enter the wallet address where you sent the
                    <br />
                    usdt from and press verify. After confirmation we
                    <br />
                    will deposit the funds in your wallet or bank account
                  </Link>
                </nav>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "flex-start",
                  alignItems: "flex-start",
                  width: "100%",
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  marginLeft: "-6%",
                  padding: 10,
                }}
              >

<nav>
                  <Link to={"/orderstatus"} style={{ fontSize: 17, color: "#8BFBAF", fontWeight: "bold", marginTop: 5 }}>
                    <a>
                      Order Status
                    </a>
                  </Link>
                </nav>

                <nav>
                  <Link to={"https://rupex.in/guide"} style={{ fontSize: 17, color: "#8BFBAF", fontWeight: "bold", marginTop: 5 }}>
                    <a>
                      Guide
                    </a>
                  </Link>
                </nav>
                <nav>
                  <Link to={"https://rupex.in/faq"} style={{ fontSize: 17, color: "#8BFBAF", fontWeight: "bold", marginTop: 5 }}>

                    <a>
                      FAQ
                    </a>
                  </Link>
                </nav>
                <nav>
                  <Link to={"https://rupex.in/contact"} style={{ fontSize: 17, color: "#8BFBAF", fontWeight: "bold", marginTop: 5 }}>

                    <a  >
                    Contact Us
                    </a>
                  </Link>
                </nav>
              </div>
            </div>
          </div>
        ) : null}
        {innerWidth < 700 ? (
          <div>
            
            {innerWidth < 700 ? (
              <div style={{
                display:'flex',
                justifyContent:'space-between'
              }}>     
               <a
                href="https://rupex.in" rel="noreferrer"
                style={{width:100}}
              >
                <img

                  src={logo}
                  style={{
                    width: "13vw",
                    float: "left",
                    marginLeft: 20,
                    marginTop: 15
                  }}
                />
              </a>
              <div style={{alignSelf:'flex-end'}}>
              <Main/>
              </div>
              </div>

            ) : null}
            <div
              id="detail"
              style={{
                width: innerWidth > 900 ? null : "90vw",
                marginTop: "10vh",
              }}
            >
              <Outlet />
            </div>
          </div>
        ) : (
          <div id="detail" style={{ width: innerWidth > 900 ? null : "90vw" }}>
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
}
