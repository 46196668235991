import currencyapi from '@everapi/currencyapi-js'
const { exchangeRates } = require('exchange-rates-api');
const axios = require('axios');
export const getExchangeRate = async ()=>{
  const response =  await exchangeRates().latest().symbols(['USD', 'INR']).fetch();     
  console.log('exchange rate',response)

}
export const getUsdToInr = async ()=>{

   
try{

    const client = new currencyapi('iW9bXxgwhcPPd5ady5NXlh1inqnPIp2ycTh4EEhc')
   const response = client.latest({
        base_currency:'USD',
        currencies:'INR'
    }).then(response => {
        console.log(response.data.INR.value)
        return response.data.INR.value
    });
    return response
}catch(error){
    console.log(error)
    return error
}
    
}

export const getTokenPrice = async (token)=>{
    const response =await fetch(`https://min-api.cryptocompare.com/data/price?fsym=${token}&tsyms=USD`)
    .then((res)=>res.json())
    .then((resJson)=>{
      console.log("Eth Current price",resJson)
      return resJson
    })
    return response
  }
