import { render } from "react-dom";
import { useForm } from "react-cool-form";
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  TextField,
  Select,
  Checkbox,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import Button from "@mui/material/Button";

import "./styles.scss";
import React, { useEffect, useState } from "react";
import ManualCard from "../Card";
import database from "../firebase/firebase";
import Main from "../Main";
import useWindowSize from "@rooks/use-window-size";
import { useLocation, useNavigate } from "react-router-dom";
import { checkAccountBeneficiery, checkAccountName, checkBankName, checkIFSC, isValid_Bank_Acc_Number, isValid_Phone_Number, isValid_UPI_ID } from "../functions/Bankaccount";
import DropDownWallet from "../dropdown/DropDown";
import SnackbarComponent from "../../utils/Alert";
import { collection, getDocs, addDoc } from "firebase/firestore";
import OrderModal from "./Order.modal";

export default function OrderStatus() {
  const [manualPage, setManualPage] = useState(false);
  const [load, setLoad] = useState(false);
  const [label, setLabel] = useState("personal");
  const[openSnackbar, setSnackbar] = React.useState(false)
  const[snackbarMessage, setSnackbarMessage] = React.useState('')
  const [type, setType] = React.useState('')
  const { innerWidth, innerHeight, outerHeight, outerWidth } = useWindowSize();
  const [msg, setMsg] = useState('')
  const location = useLocation();
  const navigate = useNavigate();
  const amount = location?.state?.amount ? location.state.amount : 0;
  const usdt = location?.state?.amount ? location.state.usdt : 0;
  const [disable,setDisable] = useState(true)
  const [data, setData] = useState();
  const [pendingTx, setPendingTx]= useState([])
  const [completedTx, setCompletedTx] = useState([])
  const [open, setOpen] = useState(false)
  const [orderData, setOrderData] = useState()
  const { form, use } = useForm({
    defaultValues: { username: "", framework: "", fruit: [], mood: "" },
    onSubmit: (values) => setManualPage(true),
  });
  const errors = use("errors");
  const getTransactions = async () => {
    const response = await getDocs(collection(database, "transactions")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        //setLoad(false);
        setPendingTx(newData)
        return newData;
      }
    );

    return response;
  };
  const getCompletedTransactions = async () => {
    const response = await getDocs(collection(database, "completed")).then(
      (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
       //console.log(newData)
      // setData(newData)
       // setLoad(false);
       setCompletedTx(newData)
        return newData;
      }
    );

    return response;
  };

 useEffect(()=>{
    getTransactions()
    getCompletedTransactions()
},[])
  
  return (
    <div 
    className="App"
    style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}
    >
     
      <div
        style={{
          width: innerWidth < 700 ? "90vw" : "25vw",
          borderWidth: 2,
          borderColor: "black",
          border: "2px solid cream",
          marginTop: "10px",
          backgroundColor: "#F5EEE6",
          boxShadow: "0 0 0px black",
          padding: 30,
          border: '2px solid white',
          borderRadius: '10px',

        }}
      >
         <h5
        style={{
          fontSize: innerWidth < 700 ? 17 : 23.5,
          fontWeight:'bolder',
          color: "black",
          paddingBottom:'10%',
          marginTop:5

        }}
      >
         Transaction Status
      </h5>

        <form
          ref={form}
          onSubmit={() => {
            // setManualPage(true);
            console.log(data);
          }}
          style={{
            marginTop: 0,
            display: "flex",
            flexDirection: "column",
            width: innerWidth < 700 ?'70vw':"",
            marginLeft:innerWidth < 700 ?22:5
          }}
        >
          
        
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "center",
                width: innerWidth < 800 ? "91%" : "20vw",
                marginTop:10,
                marginLeft:innerWidth < 800 ?0:0,
                borderRadius: '10px',

              }}
            >
               <InputLabel style={{ color: "black", marginTop: 10,alignSelf:'flex-start',marginLeft:innerWidth < 700 ?15:5,fontSize:innerWidth < 700?13:''  }}>
                Transaction ID
              </InputLabel>
              <TextField
                style={{
                  border: "2px solid white",
                  backgroundColor: "white",
                  marginTop: 10,
                  borderRadius: '10px',

                }}
                InputProps={{ disableUnderline: true }}
                name={data}
                required
                error={!!errors.username}
                helperText={errors.username}
                value={data}
                onChange={(e) => {
                  setData( e.target.value);
                  if(e.target.value)
                  {
                    setDisable(false)
                  }else{
                    setDisable(true)
                  }
                 
                }}
              />
             
            </div>
        
        </form>
        <div>
          <p
            style={{
              color:'red'
            }}
          >{msg}</p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            justifyItems: "center",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={disable}
            sx={{
              width: innerWidth < 700 ?"58vw":"20vw",
              height:innerWidth < 700 ?"7vh": "7vh",
              backgroundColor: disable?'grey':"#2637FF",
              borderRadius: '10px',
              fontSize:innerWidth < 700 ?14:20,
              marginTop:5,
              fontWeight:"bold",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}
            onClick={() => {
              let found = false
               const status = pendingTx.map((item)=>{
                  if(item.id==data)
                  {
                    console.log('found')
                    let data = item
                    data.status = 'Pending'
                    setOrderData(data)
                    setOpen(true)
                    found = true
                    return item
                  }
               })
               const status2 = completedTx.map((item)=>{
                if(item.id==data)
                {
                  console.log('found')
                  let data = item
                  data.status = 'Processed'
                  setOrderData(data)
                  setOpen(true)
                  found = true
                  return item
                }
             })
               console.log(found)
               if(!found)
               {
                setSnackbarMessage('Transaction not found. Please enter a valid transaction id')
                setType('error')
                setSnackbar(true)
               }
              }}
              
          >
           get order status
          </Button>
        </div>
      </div>
      <SnackbarComponent open={openSnackbar} setOpen={setSnackbar} message={snackbarMessage} type={type}/>
      <OrderModal open={open} setOpen={setOpen} data={orderData}/>
      </div>
  );
}
