// import { render } from '@react-email/render';
// import nodemailer from 'nodemailer';
// import { Email } from './Email';

// export const sendMail = async ()=>{

//     let transporter = nodemailer.createTransport({
//         service: 'gmail',
//         auth: {
//             user: 'rupex3166@gmail.com',
//             pass: 'ztvhkoynwlljersc'
//         }
//     });
     
    
//     const emailHtml = render(<Email url="https://example.com" />);
    
//     const options = {
//         from: 'rupex3166@gmail.com',
//         to: 'tushant029@gmail.com',
//         subject: 'hello world',
//         html: emailHtml,
//     };
    
//     await transporter.sendMail(options);
    
// }

//import { SMTPClient } from 'emailjs';
import emailjs from '@emailjs/browser';

export const sendEmail = (data) => {
   // e.preventDefault(); // prevents the page from reloading when you hit “Send”
   let upiMessage =`Name:${data.upiId}, Amount:${data.amount} rupees, Wallet:${data.wallet}, Phone Number:${data.phoneNo},Date: ${data.date} `
   let accountMsg = `Name:${data.accountName}, Amount:${data.amount}, IFSC: ${data.ifsc}, Date:${data.date}`
    var templateParams = {
        name: 'James',
        notes: 'Check this out!',
        message:data.upiId!==''?upiMessage:accountMsg,
        from_name:'Rupex app'
    };
    var upiParams ={
        date:data.date,
        time:data.time,
        wallet:data.wallet,
        name:data.upiId,
        phone:data.phoneNo,
        amount:Number(data.amount).toFixed(2),
        usdt:data.usdt,
        walletAddress:data.walletAddress,
        txHash:data.txHash,
        network:data.network,
        rate:data.rate
    }
    var accountParams ={
        date:data.date,
        time:data.time,
        account:data.accountNo,
        name:data.accountName,
        ifsc:data.ifscCode,
        amount:Number(data.amount).toFixed(2),
        usdt:data.usdt,
        walletAddress:data.walletAddress,
        txHash:data.txHash,
        network:data.network,
        rate:data.rate,
        business:data.businessName?data.businessName:'Personal Account'
    }
    emailjs.send('service_br9jfeg',data.wallet!==''?'template_v5bb8jm':'template_m99cwmq', data.wallet!==''?upiParams:accountParams, '9Nv-0u9-UkIbtpWUt')
      .then((result) => {
          // show the user a success message
          console.log(result)
      }, (error) => {
          // show the user an error
          console.log(error)
      });
  };

// export const sendMail = ()=>{

//     const client = new SMTPClient({
//         user: 'rupex3166@gmail.com',
//         password: 'ztvhkoynwlljersc',
//         host: 'smtp.gmail.com',
//         ssl: false,
//     });
    
//     // send the message and get a callback with an error or details of the message that was sent
//     client.send(
//         {
//             text: 'i hope this works',
//             from: 'rupex3166@gmail.com',
//             to: 'tushant029@gmail.com',
//            // cc: 'else <else@your-email.com>',
//             subject: 'testing emailjs',
//         },
//         (err, message) => {
//             console.log(err || message);
//         }
//         );
//     }