import { collection, getDocs, addDoc,deleteDoc,doc } from "firebase/firestore";
import database from '../../components/firebase/firebase';
import { useEffect, useState } from "react";
import { sendEmail, sendMail } from "../../utils/sendEmail";
//import { sendMail } from "../../utils/sendEmail";
import './tx.css'
import { Last24HrCard, PendingCard, RatesCard, ReusableCard, ReusableCardDollar, ReusableCardMargin } from "../cards/Cards";
import { getExchangeRate, getTokenPrice, getUsdToInr } from "../../apis/currency";
import { getBnbBalance, getEthBalance, getTronUsdtBalance } from "../../components/functions/web3Functions";
import { tronWallet } from "../../components/constants";

export const Home = ()=>{
    const [data,setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [rate, setRate] = useState(83)
    const[bnbBalance, setBnbBalance] = useState(0)
    const [tronBalance, setTronBalance] = useState(0)
    const [ethBalance, setEthBalance] = useState(0)
    const [last24, setLast24] = useState([])
    const [last7, setLast7] = useState([])
    const [last30, setLast30] = useState([])
    const[Volume30, set30DaysVolume] = useState(0)
    const[Volume7, set7DaysVolume] = useState(0)
    const[Volume1, set1DaysVolume] = useState(0)
    const [fullTx, setFullTx] = useState()
    const getAllTx = async()=>{
      const response = await getTransactions()
      const response2 = await getCompletedTransactions()
      //console.log(response, response2)
      if(response)
      {
  
       const response3= response.concat(response2)
        console.log(response3)
        setFullTx(response3)
        console.log('all tx set')
        return response3
      }
    }
  
    const [walletBalance, setWalletBalance] = useState(0)

    const getTransactions = async () => {
        setLoading(true)
        const response = await getDocs(collection(database, "transactions")).then(
          (querySnapshot) => {
            const newData = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
           console.log(newData)
           setData(newData)
           // setLoad(false);
           setLoading(false)
            return newData;
          }
        );
    
        return response;
      };

      const getCompletedTransactions = async () => {
        const response = await getDocs(collection(database, "completed")).then(
          (querySnapshot) => {
            const newData = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
           //console.log(newData)
          // setData(newData)
           // setLoad(false);
            return newData;
          }
        );
    
        return response;
      };

      const getLast24Tx = async (fullTx)=>{
       
           setLast24(fullTx.filter((item)=>{
            console.log(item.date)
            const timestampString = item.date;

            // Split the timestamp into date and time parts
            const [datePart, timePart] = timestampString.split(", ");
            const [day, month, year] = datePart.split("-");
            const [hour, minute, second] = timePart.split(":");
            
            // Create a new Date object
            const providedDate = new Date(year, month - 1, day, hour, minute, second);
            
            // Get the current date and time
            const currentDate = new Date();
            
            // Calculate the time difference in milliseconds
            const timeDifference = currentDate - providedDate;
            
            // Calculate the number of milliseconds in a day
            const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
            
            // Check if the time difference is smaller than 1 day
            if (timeDifference < oneDayInMilliseconds) {
              console.log("The time difference is smaller than 1 day.");
              return item
            } else {
              console.log("The time difference is equal to or greater than 1 day.");
              return
            }
           }))           // setLoad(false);
           setLoading(false)
         
      }
      const getLast7DaysTx = async (fullTx)=>{
       
           setLast7(fullTx.filter((item)=>{
            const timestampString = item.date;

            // Split the timestamp into date and time parts
            const [datePart, timePart] = timestampString.split(", ");
            const [day, month, year] = datePart.split("-");
            const [hour, minute, second] = timePart.split(":");
            
            // Create a new Date object
            const providedDate = new Date(year, month - 1, day, hour, minute, second);
            
            // Get the current date and time
            const currentDate = new Date();
            
            // Calculate the time difference in milliseconds
            const timeDifference = currentDate - providedDate;
            
            // Calculate the number of milliseconds in a day
            const oneDayInMilliseconds = 7*24 * 60 * 60 * 1000;
            
            // Check if the time difference is smaller than 1 day
            if (timeDifference < oneDayInMilliseconds) {
              console.log("The time difference is smaller than 1 day.");
              return item
            } else {
              console.log("The time difference is equal to or greater than 1 day.");
              return
            }
           }))           // setLoad(false);
           setLoading(false)
        
      }

      const getLast30DaysTx = async (fullTx)=>{
      
         
           setLast30(fullTx.filter((item)=>{
            const timestampString = item.date;

            // Split the timestamp into date and time parts
            const [datePart, timePart] = timestampString.split(", ");
            const [day, month, year] = datePart.split("-");
            const [hour, minute, second] = timePart.split(":");
            
            // Create a new Date object
            const providedDate = new Date(year, month - 1, day, hour, minute, second);
            
            // Get the current date and time
            const currentDate = new Date();
            
            // Calculate the time difference in milliseconds
            const timeDifference = currentDate - providedDate;
            
            // Calculate the number of milliseconds in a day
            const oneDayInMilliseconds =30* 24 * 60 * 60 * 1000;
            
            // Check if the time difference is smaller than 1 day
            if (timeDifference < oneDayInMilliseconds) {
              console.log("The time difference is smaller than 1 day.");
              return item
            } else {
              console.log("The time difference is equal to or greater than 1 day.");
              return
            }
           }))           // setLoad(false);
           setLoading(false)
          
        
      }
      const getRates = async()=>{
        const rate = await getUsdToInr()
        setRate(rate)
      }
      const getBalance = async()=>{
       const balance = await getBnbBalance('0x55d398326f99059ff775485246999027b3197955')
       setBnbBalance(Number(balance).toFixed(2))
      }

      const get30DaysVolume = (data)=>{
        data.map((item)=>{
         // console.log(item)
          if(item.usdt)
          {
            set30DaysVolume((balance)=>{
              return balance+Number(item.usdt)
            })
           // console.log('Volume30',Volume30)
          }
        })
      }
      const get7DaysVolume = (data)=>{
        data.map((item)=>{
         // console.log(item)
          if(item.usdt)
          {
            set7DaysVolume((balance)=>{
              return balance+Number(item.usdt)
            })
           // console.log('Volume30',Volume30)
          }
        })
      }
      const get1DaysVolume = (data)=>{
        data.map((item)=>{
         // console.log(item)
          if(item.usdt)
          {
            set1DaysVolume((balance)=>{
              return balance+Number(item.usdt)
            })
           // console.log('Volume30',Volume30)
          }
        })
      }
      useEffect(()=>{
       // getRates()
       
        getTransactions()
        getAllTx()
        .then((response)=>{
          getLast30DaysTx(response)
          getLast24Tx(response)
          getLast7DaysTx(response)
        }).finally(()=>{
         
        })
       
        //getBnbBalance('0x55d398326f99059ff775485246999027b3197955')
        getBalance()
       
        getTronUsdtBalance(tronWallet,setTronBalance)
        .then((resp)=>{
          console.log('resp',resp)
          getTokenPrice('TRX')
          .then((response)=>{
            if(response)
            {
              console.log('USD', response.USD)
              console.log(walletBalance)
              const balance = Number(resp)*1
              const Total = walletBalance+balance
              if(Total)
              {
  
                console.log('my balance', Total.toFixed(2))
                setWalletBalance(Total.toFixed(2))
              }
            }
          })
        }).then(()=>{
          getEthBalance('0xdAC17F958D2ee523a2206206994597C13D831ec7')
          .then((resp)=>{
            if(resp)
            {
  
              setEthBalance(resp)
              getTokenPrice('USDT')
            .then((response)=>{
              if(response)
              {
                console.log('USD Eth', response.USD)
                console.log(walletBalance)
                const balance = Number(resp)*1
                const Total = walletBalance+balance
                if(Total)
                {
    
                  console.log('my balance usd eth', Total.toFixed(2))
                  setWalletBalance((Balance)=>{
                      return Number(Balance)+balance
                  })
                }
              }
            })
            }
          })
        })
       
      //  getRates()
      },[])


      useEffect(()=>{
        get30DaysVolume(last30)
          get7DaysVolume(last7)
          get1DaysVolume(last24)
      },[last30,last7,last24])
     
    return(
        <div style={{
          display:'flex',
          justifyContent:'center',
          flexDirection:'column',
          marginLeft:200
        }}>
           <h5 
           className="textstyle"
            >Dashboard</h5>
          
              <div  style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',

            }}>
              
              <PendingCard Pending={data?.length}/>
              <RatesCard rates={rate}/>
              <Last24HrCard data={walletBalance}/>

              </div>
              <div  style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',

            }}>
              <ReusableCard title={'Last 24hr'} data={last24.length}/>

              <ReusableCardDollar title={'24hr Volume'} data={Volume1?Volume1.toFixed(2):0}/>
              <ReusableCardMargin title={'Tron'} data={tronBalance?tronBalance:0}/>
              </div>
              <div  style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',

            }}>
              
              <ReusableCard title={'7 day orders'} data={last7.length}/>
              <ReusableCardDollar title={'7 day volume'} data={Volume7?Volume7.toFixed(2):0}/>
              <ReusableCardMargin title={'Eth'} data={ethBalance?ethBalance:0}/>
              </div>
              <div  style={{
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',

            }}>
              
              <ReusableCard title={'30 day orders'} data={last30.length}/>
              <ReusableCardDollar title={'30 day volume'} data={Volume30?Volume30.toFixed(2):0}/>
              <ReusableCardMargin title={'Binance'} data={bnbBalance}/>
              </div>
            {/* <button 
            onClick={()=>{
               // sendMail()
               //sendMail()
               sendEmail('tushant',300)
            }}
            >email</button> */}
        </div>
    )
}