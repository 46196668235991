import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { processTransactionCheck } from '../apis/sha';
export default function CircularIntegration({SaveTx,item,getTransactions,processTransaction}) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();
 const navigate = useNavigate()
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = async () => {
    try{

      console.log(item)
      if (!loading) {
      setLoading(true);
      await processTransactionCheck(`${Number(item.amount).toFixed(2)}`,`${item.accountNo}`,`${item.accountName}`,`${item.ifscCode}`,item?.bankName?`${item.bankName}`:'sbi')
      .then(async (response)=>{
        console.log('My response',response.data.data)
        item.paymentId = response.data.data.transaction_id
          console.log(item)

        if(response.data.success==true || response.status==200)
        {
         
          console.log(response)
          item.paymentId = response.data.data.transaction_id
          console.log(item)
          await SaveTx(item)
          .then(()=>{
            setLoading(false)
            setSuccess(true)
            // getTransactions()
          }).finally(()=>{
            setTimeout(() => {
              
              getTransactions()
              .then(()=>{
                
                setSuccess(false)
              })
            }, 2000);
            
          })
        }else{
          setLoading(false)
          setSuccess(false)
          return alert('Failed to process transaction. Please try again')
        }
      })
      .catch((e)=>{
        console.log(e)
        setLoading(false)
        setSuccess(false)
        return alert('Failed to process transaction. Please try again')
      })
  
      //setSuccess(false);
      // setLoading(true);
      //  await SaveTx(item)
    //   .then(()=>{
    //     setLoading(false)
    //     setSuccess(true)
    //    // getTransactions()
    //   }).finally(()=>{
    //     setTimeout(() => {
          
    //       getTransactions()
    //       .then(()=>{
            
    //         setSuccess(false)
    //       })
    //     }, 2000);

    //   })
   }
  }catch(e){
      console.log(e)
      setLoading(false)
        setSuccess(false)
        return alert('Failed to process transaction. Please try again')
  }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ m: 1, position: 'relative' }}>
        <Fab
          aria-label="save"
          color="primary"
          sx={buttonSx}
          style={{
            backgroundColor:success?green[500]:'#2637FF'
          }}
          onClick={handleButtonClick}
        >
          {success ? <CheckIcon /> : <AccessTimeIcon />}
        </Fab>
        {loading && (
          <CircularProgress
            size={68}
            sx={{
              color: green[500],
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
      </Box>
  );
}